// import { eventChannel, END, runSaga } from 'redux-saga'
import {
	call,
	put,
	takeLeading,
	take,
	actionChannel,
	select,
} from "redux-saga/effects"

import * as gApi from "../../actions/services"
import * as Api from "./services"
import { toastr } from "react-redux-toastr"
import I18n from "../../i18n"
import {
	addLCS,
	removeLCS,
	downloadFiles,
	generateFileHashWithoutContent,
	backwardCompApi,
	getAuthorizedChildren,
} from "../../actions/generalUtils"
import { v4 as uuidv4 } from "uuid"

//import fs from "browserify-fs"
//import archiver from "archiver"

export function* getFiles(action) {
	try {
		addLCS(action)

		yield put({ type: "files/setLAR" })
		if (!action.payload?.offset && !action.payload.setInLar)
			yield put({ type: "files/toggleLoading", payload: true })

		let editPayload = action.payload
		if (action.payload.search.charAt(0) === "#") {
			editPayload = {
				...action.payload,
				search: action.payload.search.substring(1),
			}
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...editPayload }

		const data = yield call(Api.getFiles, payload)

		if (!action.payload?.offset && !action.payload.setInLar) {
			yield put({ type: "files/toggleLoading", payload: false })
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				//if( action.payload.setInLar ) return yield put({ type: "files/setLAR", payload: {message: "successFolder", data } })

				yield put({ type: "files/unselectAll" })

				return yield put({
					type: "files/initSearch",
					payload: { files: data },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function findRep(a) {
	const { data, action } = a
	let rep = {}

	const searchForRep = (reps) => {
		reps?.forEach((folder) => {
			if (folder?.id === action?.payload?.categoryId) {
				rep = folder
			} else if (folder?.children?.length > 0) {
				searchForRep(folder?.children)
			}
		})
	}

	searchForRep(data)

	return rep
}

export function checkIsInCat(data) {
	let newData = []
	data.tree.map((item) => {
		let a = data.ids.includes(item.id)
			? { isInCategory: true }
			: { isInCategory: false }
		newData.push({ ...item, ...a })
	})
	return newData.sort((a, b) =>
		a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
	)
}

export function* getRootFolders(action) {
	try {
		addLCS(action)

		yield put({ type: "files/setLAR" })

		if (backwardCompApi("1.20.16") && action?.keepLoaders) {
			yield put({ type: "files/toggleLoading", payload: true })
			yield put({ type: "files/setCR", payload: true })
		}

		if (
			!action.payload?.offset &&
			!action.payload.setInLar &&
			!action?.keepLoaders
		)
			yield put({ type: "files/toggleLoading", payload: true })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT }

		let isArboLoaded = yield select((state) => state.pass.isArboLoaded)

		const d = yield call(Api.getRootFolders, payload)

		if (
			!action.payload?.offset &&
			!action.payload.setInLar &&
			!action?.keepLoaders
		) {
			yield put({ type: "files/toggleLoading", payload: false })
		}

		switch (d) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//if( action.payload.setInLar ) return yield put({ type: "files/setLAR", payload: {message: "successTree", data } })
				let data = checkIsInCat(d)
				let copyData = [...data]

				if (backwardCompApi("1.20.16") && !isArboLoaded?.share) {
					yield put({
						type: "files/setArboStatus",
						payload: { space: "share" },
					})
				}

				copyData.map((item) => {
					if (item.children) return delete item.children
					else return undefined
				})

				yield put({
					type: "files/setSharedSpaceRootFolders",
					payload: copyData,
				})

				return yield put({
					type: "files/addReps",
					payload: {
						stateTarget: 1,
						data: copyData,
						doNotKeep: true,
						initArbo: action.payload.initArbo,
					},
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getFolder(action) {
	try {
		addLCS(action)

		yield put({ type: "files/setLAR" })
		if (!action.payload?.offset && !action.payload.setInLar)
			yield put({ type: "files/toggleLoading", payload: true })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, id: action.payload.categoryId }

		const data = yield call(Api.getFolder, payload)

		if (!action.payload?.offset && !action.payload.setInLar) {
			yield put({ type: "files/toggleLoading", payload: false })
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//console.log(data)

				if (action.payload.setInLar)
					return yield put({
						type: "files/setLAR",
						payload: { message: "successFolder", data },
					})
				if (action.payload.search) return data

				let copyData = { ...data }

				copyData.users = [...copyData.groups, ...copyData.users]
				delete copyData.groups

				//yield put({ type: "files/addReps", payload: { data: data.files, doNotKeep: true } })
				yield put({
					type: "files/updateData",
					payload: { ...copyData },
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getTree(action) {
	try {
		addLCS(action)

		yield put({ type: "files/setLAR" })
		if (!action.payload?.offset && !action.payload.setInLar)
			yield put({ type: "files/toggleLoading", payload: true })
		if (action.payload.setInLar) {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = { token, xLsT, organizationId, ...action.payload }

		const d = yield call(Api.getTree, payload)

		if (!action.payload?.offset && !action.payload.setInLar) {
			yield put({ type: "files/toggleLoading" })
		}
		if (action.payload.setInLar)
			yield put({ type: "action/setActionLoader" })

		switch (d) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = yield call(checkIsInCat, { ...d })
				let rights = d.rights

				if (action.payload.setInLar)
					return yield put({
						type: "files/setLAR",
						payload: { message: "successTree", data, rights },
					})

				return data
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getPersoTree(action) {
	try {
		//console.log("perso", action)
		addLCS(action)

		yield put({ type: "files/setLAR" })
		if (backwardCompApi("1.20.16") && action?.keepLoaders) {
			yield put({ type: "files/toggleLoading", payload: true })
			yield put({ type: "files/setCR", payload: true })
		}

		if (
			!action.payload?.offset &&
			!action.payload.setInLar &&
			!action?.keepLoaders
		)
			yield put({ type: "files/toggleLoading", payload: true })
		if (action.payload.setInLar) {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, ...action.payload }

		let isArboLoaded = yield select((state) => state.pass.isArboLoaded)

		const d = yield call(Api.getPersoTree, payload)

		if (
			!action.payload?.offset &&
			!action.payload.setInLar &&
			!action?.keepLoaders
		) {
			yield put({ type: "files/toggleLoading", payload: false })
		}
		if (action.payload.setInLar)
			yield put({ type: "action/setActionLoader" })

		switch (d) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				let data = d.sort((a, b) =>
					a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
				)

				if (backwardCompApi("1.20.16") && !isArboLoaded?.perso) {
					yield put({
						type: "files/setArboStatus",
						payload: { space: "perso" },
					})
				}

				if (action.payload.setInLar)
					return yield put({
						type: "files/setLAR",
						payload: { message: "successPersoTree", data },
					})

				if (action.payload.initCall) {
					let rPerso = data.find((x) => x.name === "Root Perso")

					if (rPerso && rPerso.id === 0 && rPerso.parentId === -1) {
						if (
							!action.payload.initArbo &&
							rPerso.files.length > 0
						) {
							yield put({
								type: "files/enterInFolder",
								payload: {
									justRefresh: true,
									files: rPerso.files,
								},
							})
						}

						data = data.filter((x) => x.name !== "Root Perso")
					}

					if (action?.payload?.fileId && rPerso) {
						if (
							!rPerso?.files?.find(
								(file) =>
									file?.id ===
									parseInt(action?.payload?.fileId),
							)
						) {
							return yield put({
								type: "files/setErrorNotFound",
								payload: true,
							})
						}
					}

					let copyData = [...data]

					// Todo: check Retrocompat
					if (!backwardCompApi("1.20.16")) {
						copyData.map((item) => {
							if (item.children) return delete item.children
							else return undefined
						})
					}

					return yield put({
						type: "files/addReps",
						payload: {
							stateTarget: 0,
							data: copyData,
							doNotKeep: true,
							initArbo: action.payload.initArbo,
						},
					})
				} else if (action.payload.justRefresh) {
					let copyData
					if (action.payload.categoryId === -1) {
						return yield put({
							type: "files/addReps",
							payload: {
								data: data,
								doNotKeep: true,
							},
						})
					} else {
						let history = yield select(
							(state) => state.files.history,
						)

						let newData = yield call(findRep, {
							action,
							data,
							history,
						})

						copyData = { ...newData }

						if (copyData.children) {
							copyData.reps = copyData.children
							delete copyData.children
						}

						return yield put({
							type: "files/addReps",
							payload: {
								data: copyData.reps,
								doNotKeep: true,
								keepArbo: action?.payload?.keepArbo,
							},
						})
					}
				} else {
					let history = yield select((state) => state.files.history)
					let newData = yield call(findRep, { action, data, history })

					let copyData = { ...newData }
					if (copyData.children) {
						copyData.reps = copyData.children
						delete copyData.children
					}

					yield put({
						type: "files/addReps",
						payload: {
							data: copyData.reps,
						},
					})
					return yield put({
						type: "files/updateData",
						payload: { ...copyData },
					})
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

function checkUtilMap(data, ids) {
	let keep
	data.map((item) => {
		if (ids.includes(item.id)) return (keep = true)
		else if (item.children && !keep)
			return (keep = checkUtilMap(item.children, ids))
	})
	return keep
}

function checkDeadEnd(tree, ids) {
	let a = tree.children
	let reps = []

	a.map((item) => {
		let keep = false

		if (ids.includes(item.id)) keep = true
		else if (item.children) keep = checkUtilMap(item.children, ids)

		if (keep) return reps.push(item)
		else return
	})

	return { ...tree, reps }
}

export function* getBranch(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		if (!action.payload.noLoader && backwardCompApi("1.20.16")) {
			yield put({
				type: `files/toggleChildrenLoading`,
				payload: { loading: true, children: action.payload.mainId },
			})
		}

		let payload = { token, xLsT, ...action.payload }

		const d = yield call(Api.getBranch, payload)

		switch (d) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				//if( action.payload.setInLar ) return yield put({ type: "files/setLAR", payload: {message: "successTree", data } })

				let history = yield select((state) => state.files.history)
				let dt = [...d.tree]
				let newData = yield call(findRep, { action, data: dt, history })

				if (action.justReturn) return newData

				// Todo: Retrocompat
				if (action.payload.isArbo && backwardCompApi("1.20.16")) {
					yield put({
						type: "files/addChildren",
						payload: {
							data: getAuthorizedChildren(
								newData?.children,
								d.ids,
							),
							parentId: action.payload.mainId,
						},
					})

					yield put({
						type: "files/toggleChildrenLoading",
					})
				}

				if (newData.children && !action.payload.isArbo) {
					let copyData = checkDeadEnd(newData, d.ids)

					let data = yield call(checkIsInCat, {
						tree: copyData.reps,
						ids: d.ids,
					})

					const folderState = yield select(
						(state) => state.files.data[0],
					)

					if (action.payload.categoryId !== folderState.id) return

					yield put({
						type: "files/addReps",
						payload: {
							data: data,
							doNotKeep: true,
						},
					})
				}

				if (action.getFolder && d.ids.includes(newData.id)) {
					yield put({
						type: "files/updateData",
						payload: { isInCategory: true },
					})
					yield call(getFolder, { ...action })
				} else if (action.getFolder)
					yield put({
						type: "files/updateData",
						payload: { isInCategory: false },
					})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getData(action) {
	try {
		//console.log("getDataMain", action)
		yield put({ type: "files/setCR", payload: true })

		if (!action.payload?.offset)
			yield put({ type: "files/toggleLoading", payload: true })

		if (action.payload.search) {
			yield call(getFiles, { ...action })
			yield put({ type: "files/setCR" })
		} else {
			yield put({ type: "files/unselectAll" })

			if (action.payload.initCall) {
				if (!action.payload.personalSpaceBlock)
					yield call(getPersoTree, { ...action })
				yield call(getRootFolders, { ...action })

				if (!backwardCompApi("1.20.16")) {
					yield put({ type: "files/saveBranch", payload: {} })
				}

				// Todo: retrocompat
				if (!action.payload.initArbo && backwardCompApi("1.20.16")) {
					yield put({ type: "files/saveBranch", payload: {} })
				}

				yield put({ type: "files/setCR" })
			} else {
				yield put({
					type: "files/enterInFolder",
					payload: { ...action.payload },
				})

				if (action.payload.mainId !== 0) {
					yield call(getBranch, {
						...action,
						getFolder: true,
						noLoader: action.payload.noLoader,
					})
					//if (action.payload.isInCategory !== false) yield call(getFolder, {...action})
				} else {
					yield call(getPersoTree, { ...action })
					yield call(getFolder, { ...action })
				}

				yield put({ type: "files/setCR" })
			}

			return yield put({ type: "files/toggleLoading" })
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* getFolderReport(action) {
	try {
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = {
			token,
			xLsT,
			data: { folderId: action.payload.folderId },
		}
		const data = yield call(Api.getFolderReport, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				const fileName =
					I18n.t("manage.report") + "-" + action.payload.fileName
				return downloadFiles(data, fileName)
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* postFolder(action) {
	try {
		addLCS(action)

		yield put({ type: "files/setCR", payload: true })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let auth = yield select((state) => state.auth)

		let organizationId =
			auth.token.userSession.organizationIds[auth.selectedOrg]

		let payload = {
			token,
			xLsT,
			action: { ...action.payload, organizationId },
		}

		const data = yield call(Api.postFolder, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "files/setCR" })
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({
					type: "action/setActionLoader",
					payload: { transparency: true },
				})
				yield put({ type: "action/closeAction" })

				toastr.success(I18n.t("files.addFolder.successUpload"))

				let h = yield select((state) => state.files.history)
				let s = yield select((state) => state.files.data[0])
				let activeFiles = yield select((state) => state.files.active)

				if (
					!backwardCompApi("1.20.16") &&
					h?.length === 0 &&
					[0, -1].includes(action.payload?.parentId)
				) {
					//yield put({ type: "files/init" })
					yield put({
						type: "LF_GET_DATA_SAGA",
						payload: {
							categoryId: 0,
							initCall: true,
							stateTarget: 1,
							fileId:
								Object.keys(activeFiles).length === 1
									? activeFiles?.[Object.keys(activeFiles)[0]]
											?.id
									: undefined,
						},
					})
					yield put({ type: "files/saveBranch", payload: {} })
				}

				if (backwardCompApi("1.20.16")) {
					if (
						action.payload.mainId !== 0 &&
						action.payload.mainId !== -1
					) {
						yield put({
							type: "LF_GET_BRANCH_SAGA",
							payload: {
								mainId: action.payload.mainId,
								categoryId: action.payload.mainId,
								isArbo: true,
								noLoader: true,
							},
						})
					}

					if (
						!action.payload.hasChildren &&
						action.payload.parentId !== -1 &&
						action.payload.parentId !== 0
					) {
						yield put({
							type: "LF_GET_DATA_SAGA",
							keepLoaders: true,
							payload: {
								categoryId: 0,
								initCall: true,
								stateTarget: 1,
								initArbo: s.id !== 0,
							},
						})

						yield put({
							type: "files/toggleLoading",
						})
						yield put({ type: "files/setCR" })
					}
				}

				if (
					[0, -1].includes(action.payload?.parentId) &&
					!backwardCompApi("1.20.16")
				) {
					yield put({ type: "files/init" })
					yield put({
						type: "LF_GET_DATA_SAGA",
						payload: {
							categoryId: 0,
							initCall: true,
							stateTarget: 1,
							fileId:
								Object.keys(activeFiles).length === 1
									? activeFiles?.[Object.keys(activeFiles)[0]]
											?.id
									: undefined,
						},
					})
					yield put({ type: "files/saveBranch", payload: {} })
				}

				if (
					[0, -1].includes(action.payload?.parentId) &&
					backwardCompApi("1.20.16")
				) {
					yield put({ type: "files/init" })
					yield put({
						type: "LF_GET_DATA_SAGA",
						payload: {
							categoryId: 0,
							initCall: true,
							stateTarget: 1,
							fileId:
								Object.keys(activeFiles).length === 1
									? activeFiles?.[Object.keys(activeFiles)[0]]
											?.id
									: undefined,
						},
					})
					yield put({ type: "files/saveBranch", payload: {} })
				} else if (s.id === action.payload.parentId) {
					yield put({ type: "files/init" })
					yield put({
						type: "LF_GET_DATA_PERMALINK_SAGA",
						payload: {
							fileId:
								Object.keys(activeFiles).length === 1
									? activeFiles?.[Object.keys(activeFiles)[0]]
											?.id
									: undefined,
							folderId: action.payload.parentId,
							isSharedCat: s?.mainId !== 0,
							resetBranchInfo: true,
						},
					})
				} else {
					yield put({ type: "files/saveBranch", payload: {} })
					yield put({ type: "files/setCR" })
				}
				if (action.payload.notifUpload) {
					yield put({
						type: "LF_PUT_FOLDER_NOTIFICATION_SAGA",
						payload: {
							id: data.id,
							toaster: action.payload.toasterUpload,
							data: { isNotify: action.payload.notifUpload },
						},
					})
				}

				return yield put({ type: "action/setActionLoader" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putFolder(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.putFolder, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)
				//console.log(data)
				toastr.success(I18n.t("editSuccess"))

				if (backwardCompApi("1.20.16")) {
					if (
						action.payload.mainId !== 0 ||
						action.payload.mainId !== -1
					) {
						yield put({
							type: "LF_GET_BRANCH_SAGA",
							payload: {
								mainId: action.payload.mainId,
								categoryId: action.payload.mainId,
								isArbo: true,
								noLoader: true,
							},
						})
					}

					yield put({
						type: "LF_GET_DATA_SAGA",
						payload: {
							categoryId: 0,
							initCall: true,
							stateTarget: 1,
							initArbo: true,
						},
					})
				}

				yield put({ type: "action/closeAction" })
				yield put({ type: "files/updateData", payload: data })
				yield put({ type: "files/updateTree", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* deleteFolder(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})
		//yield put({ type: "files/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, action: action.payload.data }

		const data = yield call(Api.deleteFolder, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("files.folderRemoved"))

				yield put({ type: "files/saveBranch", payload: {} })
				yield put({ type: "action/setLAR", payload: "navigateBack" })

				// TODO: Retrocompat
				if (backwardCompApi("1.20.16")) {
					if (
						action.payload.parentId !== 0 ||
						action.payload.parentId !== -1
					) {
						yield put({
							type: "LF_GET_BRANCH_SAGA",
							payload: {
								mainId: action.payload.parentId,
								categoryId: action.payload.parentId,
								isArbo: true,
								noLoader: true,
							},
						})

						let activeFiles = yield select(
							(state) => state.files.active,
						)

						yield put({
							type: "LF_GET_DATA_SAGA",
							payload: {
								categoryId: action.payload.parentId,
								initCall: true,
								stateTarget: 1,
								fileId:
									Object.keys(activeFiles).length === 1
										? activeFiles?.[
												Object.keys(activeFiles)[0]
										  ]?.id
										: undefined,
							},
						})
					} else {
						let activeFiles = yield select(
							(state) => state.files.active,
						)

						yield put({ type: "files/init" })
						yield put({
							type: "LF_GET_DATA_SAGA",
							payload: {
								categoryId: 0,
								initCall: true,
								stateTarget: 1,
								fileId:
									Object.keys(activeFiles).length === 1
										? activeFiles?.[
												Object.keys(activeFiles)[0]
										  ]?.id
										: undefined,
							},
						})
					}
				}

				return yield put({ type: "action/closeMiniAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putNotification(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		const payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.putNotification, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				action.payload.toaster && toastr.success(I18n.t("editSuccess"))
				yield put({
					type: "files/toggleUserNotifStatus",
					payload: { id: action.payload.userId },
				})
				yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

// Upload a single file. If the file is big, it will be split into chunks that will be send separatly.
export function* uploadFile(action) {
	try {
		const sagaPayload = { ...action.payload }

		yield put({ type: "files/setLAR" })

		// Here we initiate the loader.
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let token = yield select((state) => state.auth.token.token)
		let xLsT = yield select((state) => state.auth.xLsToken)

		let chunkSize = 20000000 // bytes
		let d = { ...action.payload }

		d.chunkIndex = 0
		d.uuid = uuidv4()

		if (d.file.size > 0) {
			d.totalChunkCount = Math.ceil(d.file.size / chunkSize)
		} else {
			d.totalChunkCount = 1
		}

		let data = true

		const uploadBlocked = yield select(
			(state) => state.files.stopFilesUpload,
		)

		if (uploadBlocked) {
			d.chunkIndex = d.totalChunkCount
		}

		while (d.chunkIndex < d.totalChunkCount) {
			const fileHash = yield call(generateFileHashWithoutContent, d.file)
			yield put({
				type: "action/startUpload",
				payload: { fileId: fileHash },
			})

			let offset = d.chunkIndex * chunkSize
			let blob = d.file.slice(offset, offset + chunkSize)
			d.filePart = new File([blob], d.file.name)

			if (d.sendEmail && d.chunkIndex !== d.totalChunkCount - 1) {
				delete d.sendEmail
			}

			const servicePayload = {
				token,
				xLsT,
				data: {
					...d,
					firstUpload: sagaPayload.multiPart === 0 ? "true" : "false",
					firstChunk: d.chunkIndex === 0 ? true : false,
				},
			}

			data = yield call(Api.uploadFiles, servicePayload)

			yield put({
				type: "action/endUpload",
				payload: { fileId: fileHash },
			})

			const checkData =
				data !== "sessionExpired" &&
				data !== "sessionDestroyed" &&
				data !== true

			if (checkData) {
				if (data.uncertainChunk) {
					yield put({
						type: "files/addUncertainFile",
						payload: d.file.name,
					})
				}

				sagaPayload.size -= chunkSize
				d.chunkIndex++
			} else {
				// What happens when a chunk generates an error during its upload.
				// Hide the loader
				// Remove the time estimation
				// Block the upload of the next files
				// Exit the while loop
				yield put({
					type: "files/setLAR",
					payload: { message: "abortUpload" },
				})
				yield put({
					type: "action/cancelUploadEstimation",
				})
				yield put({ type: "files/blockFilesUpload" })
				d.chunkIndex = d.totalChunkCount
			}
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "action/cancelUploadEstimation" })
				return yield put({
					type: "files/setLAR",
					payload: { message: "abortUpload" },
				})
			default: {
				const uploadEstimator = yield select(
					(state) => state.action.uploadEstimator,
				)

				// At this point, having the uploadEstimator's value at null means the upload
				// has ended.
				if (uploadEstimator === null) {
					const uncertainFiles = yield select(
						(state) => state.files.uncertainFiles,
					)
					if (uncertainFiles) {
						toastr.success(I18n.t("files.upload.successWarn"))
						yield put({ type: "files/removeUncertainFiles" })
					} else {
						toastr.success(I18n.t("files.upload.success"))
					}

					yield put({ type: "action/closeMiniAction" })
				}

				yield put({
					type: "files/setLAR",
					payload: {
						message: "fileUploaded",
						data: { ...d, uploadFinished: action.payload.isLast },
					},
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* uploadFilesAction(action) {
	try {
		yield put({ type: "files/setLAR" })
		let token = yield select((state) => state.auth.token.token)
		let xLsT = yield select((state) => state.auth.xLsToken)

		let chunkSize = 20000000 // bytes
		let d = { ...action.payload }
		let payload = { token, xLsT }

		d.chunkIndex = 0
		d.totalChunkCount = Math.ceil(d.file.size / chunkSize)
		d.uuid = uuidv4()

		let ret
		const fileId = yield call(generateFileHashWithoutContent, d)

		while (d.chunkIndex < d.totalChunkCount) {
			yield put({
				type: "action/startUpload",
				payload: { fileId },
			})

			let offset = d.chunkIndex * chunkSize
			let blob = d.file.slice(offset, offset + chunkSize)
			d.filePart = new File([blob], d.file.name)

			ret = yield call(Api.uploadFiles, { ...payload, data: d })

			yield put({
				type: "action/endUpload",
				payload: { fileId },
			})

			d.chunkIndex++
		}

		if (action.payload.isLast) {
			yield put({ type: "files/setUploadLoading" })
		}

		switch (ret) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				if (action.payload.isLast) {
					yield put({ type: "action/closeAction" })

					toastr.success(I18n.t("files.addFiles.successUpload"))
					yield put({
						type: "files/setLAR",
						payload: { message: "" },
					})

					let s = yield select((state) => state.files.data[0])

					if (action.payload.folderId === s.id) {
						if (s.id === -1)
							return yield put({
								type: "LF_GET_DATA_SAGA",
								payload: {
									justRefresh: true,
									initCall: true,
									categoryId: -1,
									mainId: 0,
								},
							})
						else
							return yield put({
								type: "LF_GET_DATA_SAGA",
								payload: {
									justRefresh: true,
									categoryId: s.id,
									mainId: s.mainId,
								},
							})
					}
				} else return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* putFile(action) {
	try {
		addLCS(action)

		if (action?.requiresRedirectInWindowHistoryState) {
			yield put({ type: "file/setCR", payload: true })
		}

		let act = action.payload

		if (!act.multiPutMode) {
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		} else {
			yield put({
				type: "files/setUploadLoading",
				payload: {
					actualPart: act.multiPutPart,
					totalParts: act.multiPutTotal,
					customMsg: `${I18n.t("files.move.loadingMovingFiles")}: ${
						act.data.name
					}`,
				},
			})
		}

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...act }

		const data = yield call(Api.putFile, payload)
		if (!act.multiPutMode) yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				if (action?.requiresRedirectInWindowHistoryState) {
					yield put({ type: "file/setCR" })
				}

				return removeLCS(action)
			default: {
				removeLCS(action)

				if (!act.multiPutMode) yield put({ type: "action/closeAction" })

				if (act && !act.moveMode) {
					toastr.success(I18n.t("editSuccess"))

					return yield put({
						type: "files/updateActive",
						payload: { id: data.id, data },
					})
				} else {
					if (act.lastMove) {
						yield put({ type: "files/setUploadLoading" })
						yield put({ type: "action/closeAction" })

						toastr.success(I18n.t("files.move.success"))
						let s = yield select((state) => state.files.data[0])

						yield put({
							type: "action/setLAR",
							payload: "navigateBack",
						})
						yield put({ type: "file/unselectAll" })

						if (action?.requiresRedirectInWindowHistoryState) {
							if (s.id === -1)
								return yield put({
									type: "LF_GET_DATA_SAGA",
									payload: {
										initCall: true,
										categoryId: -1,
										mainId: 0,
										keepArbo: backwardCompApi("1.20.16"),
									},
								})
							else
								return yield put({
									type: "LF_GET_DATA_SAGA",
									payload: {
										justRefresh: true,
										categoryId: s.id,
										mainId: s.mainId,
										keepArbo: backwardCompApi("1.20.16"),
										noLoader: true,
									},
								})
						}
					}
				}

				return
			}
		}
	} catch (error) {
		console.log(error)
	}
}

export function* deleteFile(action) {
	try {
		addLCS(action)

		if (!action?.fromUpload) {
			yield put({ type: "files/setCR", payload: true })
			yield put({
				type: "action/setActionLoader",
				payload: { transparency: true },
			})
		}
		//yield put({ type: "files/setLAR"})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, action: action.payload }
		let fileId

		if (action.payload.canEstimate) {
			// if we're estimating the upload time, the saga payload
			// contains more than just the file id, so we need to change
			// the service payload.
			payload.action = action.payload.payload

			fileId = yield call(
				generateFileHashWithoutContent,
				action.payload.file,
				"delete",
			)

			yield put({
				type: "action/startUpload",
				payload: {
					fileId,
				},
			})
		}

		const data = yield call(Api.deleteFile, payload)

		if (action.payload.canEstimate) {
			fileId = yield call(
				generateFileHashWithoutContent,
				action.payload.file,
				"delete",
			)
			yield put({
				type: "action/endUpload",
				payload: {
					fileId,
				},
			})
		}

		if (!action?.fromUpload) {
			yield put({ type: "action/setActionLoader" })
		}

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				yield put({ type: "file/setCR" })
				return removeLCS(action)
			default: {
				yield put({ type: "files/unselectAll" })

				if (action?.fromUpload) {
					return
				}

				removeLCS(action)

				toastr.success(I18n.t("files.fileRemoved"))

				yield put({ type: "action/closeMiniAction" })
				yield put({ type: "action/closeAction" })

				let s = yield select((state) => state.files.data[0])

				yield put({
					type: "action/setLAR",
					payload: "navigateBack",
				})

				if (action?.requiresRedirectInWindowHistoryState) {
					if (s.id === -1)
						yield put({
							type: "LF_GET_DATA_SAGA",
							payload: {
								justRefresh: true,
								initCall: true,
								categoryId: -1,
								mainId: 0,
							},
						})
					else
						yield put({
							type: "LF_GET_DATA_SAGA",
							payload: {
								justRefresh: true,
								categoryId: s.id,
								mainId: s.mainId,
							},
						})
				} else {
					yield put({ type: "files/setCR" })
				}
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

function* fetchBranch(action) {
	try {
		yield call(getBranch, {
			type: "LF_GET_BRANCH_SAGA",
			payload: {
				mainId: action.mainId,
				categoryId: action.mainId,
				isArbo: true,
				noLoader: true,
			},
		})
	} catch (e) {
		console.error(e)
	}
}

export function* moveFolder(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.moveFolder, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "action/closeAction" })

				let folder = yield select((state) => state.files.data[0])

				yield put({ type: "files/saveBranch", payload: [] })

				if (
					(folder.mainId === 0 && data.mainId !== 0) ||
					(folder.mainId !== 0 && data.mainId === 0)
				) {
					yield put({
						type: "action/setLAR",
						payload: "navigateBack",
					})
				}

				if (
					(folder.mainId === 0 && data.mainId === 0) ||
					(folder.mainId !== 0 && data.mainId !== 0)
				) {
					yield put({ type: "files/init" })
				}

				if (backwardCompApi("1.20.16")) {
					if (folder.mainId !== 0 || folder.mainId !== -1) {
						yield fetchBranch(folder)
					}

					yield put({
						type: "LF_GET_DATA_SAGA",
						payload: {
							categoryId: 0,
							initCall: true,
							stateTarget: 1,
							initArbo: true,
						},
					})
				}

				yield put({ type: "action/setActionLoader" })
				return toastr.success(I18n.t("moveFolderSuccess"))
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* folderAddGroup(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, action: action.payload }
		const data = yield call(Api.folderAddGroup, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("pass.editRcpt.groupAdded"))

				return yield put({ type: "files/updateData", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* folderAddUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, action: action.payload }
		const data = yield call(Api.folderAddUser, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("pass.editRcpt.userAdded"))

				return yield put({ type: "files/updateData", payload: data })
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* folderDelGroup(action) {
	try {
		addLCS(action)
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		const xLsT = yield select((state) => state.auth.xLsToken)
		const token = yield select((state) => state.auth.token.token)
		const payload = { token, xLsT, ...action.payload }

		const data = yield call(Api.folderDelGroup, payload)

		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("pass.editRcpt.groupRemoved"))

				//yield put({ type: "files/updateData", payload: { users: data.users } })

				const s = yield select((state) => state.files.data[0])

				return yield put({
					type: "LF_GET_DATA_SAGA",
					payload: {
						justRefresh: true,
						categoryId: s.id,
						mainId: s.mainId,
					},
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* folderDelUser(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.folderDelUser, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				toastr.success(I18n.t("pass.editRcpt.userRemoved"))

				//yield put({ type: "files/updateData", payload: { users: data.users } })

				let s = yield select((state) => state.files.data[0])

				return yield put({
					type: "LF_GET_DATA_SAGA",
					payload: {
						justRefresh: true,
						categoryId: s.id,
						mainId: s.mainId,
					},
				})
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* folderChangeUserGrade(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.folderChangeUserGrade, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				// If the user demoted himself, we may have to close the popup (if he's not part of a manager group)
				const userEmail = yield select(
					(state) => state.auth.token.userSession.userEmail,
				)

				if (
					!action.payload.data.hasRight &&
					userEmail === action.payload.email
				) {
					let userAlreadySpotted = false
					let stillHasRights = false

					action.payload.usersList.forEach((item) => {
						if (
							(item.isInGroup || item.email === userEmail) &&
							(item.isManager ||
								(backwardCompApi("1.19.4")
									? item.protected
									: item.right) > 0)
						) {
							if (userAlreadySpotted) stillHasRights = true
							else userAlreadySpotted = true
						}
					})

					if (!stillHasRights)
						yield put({ type: "action/closeAction" })
				}

				toastr.success(I18n.t("pass.editRcpt.userRightsChanged"))

				return yield put({
					type: "files/updateData",
					payload: { users: data.users, groups: data.groups },
				})
			}
		}
	} catch (error) {
		//return console.log(error)
	}
}

export function* folderChangeGroupGrade(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.folderChangeGroupGrade, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				// If the user was part of the group he just demoted and isn't a manager, close the popup.
				if (action.payload.isInGroup && !action.payload.data.status) {
					// Check if the user was simulaneously in a manager group and a manager.
					const userEmail = yield select(
						(state) => state.auth.token.userSession.userEmail,
					)
					let userAlreadySpotted = false
					let stillHasRights = false

					action.payload.usersList.forEach((item) => {
						if (
							(item.email === userEmail || item.isInGroup) &&
							(item.isManager ||
								(backwardCompApi("1.19.4")
									? item.protected
									: item.right) > 0)
						) {
							if (userAlreadySpotted)
								return (stillHasRights = true)
							else userAlreadySpotted = true
						}
					})

					if (!stillHasRights)
						yield put({ type: "action/closeAction" })
				}

				toastr.success(I18n.t("pass.editRcpt.userRightsChanged"))

				return yield put({
					type: "files/updateData",
					payload: { users: data.users, groups: data.groups },
				})
			}
		}
	} catch (error) {
		//return console.log(error)
	}
}

export function* getFolderSize(action) {
	try {
		yield put({ type: "files/setLAR" })

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)

		let payload = { token, xLsT, ...action.payload }
		const data = yield call(Api.getFolderSize, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				if (action?.payload?.setInLar)
					return yield put({
						type: "files/setLAR",
						payload: { message: "folderSizeRetrieved", data },
					})
				else return data
			}
		}
	} catch (error) {
		/* console.log(error) */
	}
}

export function* downloadFile(action) {
	try {
		addLCS(action)

		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let payload = { token, xLsT, action: action.payload }

		const data = yield call(Api.downloadFile, payload)
		yield put({ type: "action/setActionLoader" })

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				return
			}
		}
	} catch (error) {
		//console.log(error)
	}
}

export function* downloadFolder(action) {
	try {
		addLCS(action)

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let folderId = yield select((state) => state.files.data[0].id)
		let payload = {
			token,
			xLsT,
			data: {
				allFolder: action?.payload?.recursive ? true : false,
				folderId,
				method: "downloadFolder",
			},
		}

		const data = yield call(gApi.downloadWithToken, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return removeLCS(action)
			default: {
				removeLCS(action)

				yield put({ type: "action/closeMiniAction" })
				return yield put({ type: "action/closeAction" })
			}
		}
	} catch (error) {
		removeLCS(action)
		yield put({ type: "action/setActionLoader" })
		yield put({ type: "action/closeMiniAction" })
		return yield put({ type: "action/closeAction" })
		//console.log(error)
	}
}

export function* downloadSelectedFiles(action) {
	try {
		yield put({ type: "files/setLAR" })
		yield put({
			type: "action/setActionLoader",
			payload: { transparency: true },
		})

		let xLsT = yield select((state) => state.auth.xLsToken)
		let token = yield select((state) => state.auth.token.token)
		let folderId = yield select((state) => state.files.data[0].id)

		let active = yield select((state) => state.files.active)
		let l = Object.keys(active)

		let fileIds = ""
		l.map((key, i) => {
			if (i > 0) fileIds += ","
			return (fileIds += active[key].id)
		})

		let payload = {
			token,
			xLsT,
			data: { folderId, fileIds, method: "downloadFiles" },
		} // method: "downloadFiles"

		const data = yield call(gApi.downloadWithToken, payload)

		switch (data) {
			case "sessionExpired":
				return yield put({ type: "auth/showPadlock" })
			case "sessionDestroyed":
				return yield put({ type: "auth/sessionExpired" })
			case true:
				return
			default: {
				yield put({ type: "action/setActionLoader" })

				if (action?.payload?.setInLar)
					return yield put({
						type: "files/setLAR",
						payload: { message: "folderSizeRetrieved", data },
					})
				else return data
			}
		}
	} catch (error) {
		/* console.log(error) */
	}
}

/*
export function* downloadSelectedFiles(action) {
	try {
		yield put({ type: "files/setUploadLoading", payload: {actualPart: 0, totalParts: 100, customMsg: `1/3 ${I18n.t("files.recDl.step0")}`} })

		let active = yield select(state => state.files.active)
		let xLsT = yield select(state => state.auth.xLsToken)
		let token = yield select(state => state.auth.token.token)



		let files = []
		Object.keys(active).map((key) => {
			files.push(active[key])
		})
		//console.log(ids)


		// Taille dispo
		let freeSize = yield call(getNavSpace)
		console.log(freeSize)
		console.log("Espace disponible :", freeSize.quota)

		let tSize = 0
		console.log(files)
		files.map((item,i) => {
			tSize += item.realSize
		})


		console.log("Espace nécessaire :", tSize)
		console.log("Nécessaire sur disponible :", (100*tSize/freeSize.quota).toFixed(2), "%")

		if (tSize > freeSize.quota) {
			yield put({ type: "files/setUploadLoading" })
			yield put({ type: "action/closeMiniAction" })
			yield put({ type: "action/closeAction" })
			return toastr.error(I18n.t("files.recDl.noEnoughSpace"))
		}


		yield call(oldDownloadFolderHandle, { branch: { files }, token, xLsT, targetSize: tSize })

		// Close
		yield put({ type: "files/setUploadLoading" })
		yield put({ type: "action/closeMiniAction" })
		return yield put({ type: "action/closeAction" })

	} catch (error) {
		//console.log(error)
	}
}


export function* oldDownloadFolder(action) {
	yield put({ type: "files/setUploadLoading", payload: {actualPart: 0, totalParts: 100, customMsg: `1/3 ${I18n.t("files.recDl.step0")}`} })

	let s = yield select(state => state.files.data.[0])
	let xLsT = yield select(state => state.auth.xLsToken)
	let token = yield select(state => state.auth.token.token)
	let rec = action.payload?.recursive

	// Taille dispo
	let freeSize = yield call(getNavSpace)
	console.log(freeSize)
	console.log("Espace disponible :", freeSize.quota)


	// Taille de la cible
	let payload = { token, xLsT, id: s.id }
	let targetSize = yield call(getFolderSize, payload)
	//console.log(targetSize,s.id)


	let tSize = 0
	if (rec) {
		targetSize.map((item,i) => {
			tSize += item.totalSize
		})
	} else {
		tSize = targetSize.find(x => x.id === s.id).totalSize
	}


	console.log("Espace nécessaire :", tSize)
	console.log("Nécessaire sur disponible :", (100*tSize/freeSize.quota).toFixed(2), "%")

	if (tSize > freeSize.quota) {
		yield put({ type: "files/setUploadLoading" })
		yield put({ type: "action/closeMiniAction" })
		yield put({ type: "action/closeAction" })
		return toastr.error(I18n.t("files.recDl.noEnoughSpace"))
	}



	// Récupérer l'arborescence
	let branch
	if (rec) {
		yield put({ type: "files/setUploadLoading", payload: {actualPart: 5, totalParts: 100, customMsg: `1/3 ${I18n.t("files.recDl.step1")}`} })
		branch = yield call(getBranch, { payload: { categoryId: s.id, mainId: s.mainId }, justReturn: true })
	} else {
		yield put({ type: "files/setUploadLoading", payload: {actualPart: 10, totalParts: 100, customMsg: `2/3 ${I18n.t("files.recDl.step2")}`} })
		branch = s
	}

	//console.log(branch)
	yield call(oldDownloadFolderHandle, { rec, branch, token, xLsT, targetSize: tSize })

	// Close
	yield put({ type: "files/setUploadLoading" })
	yield put({ type: "action/closeMiniAction" })
	return yield put({ type: "action/closeAction" })
}



export function* oldDownloadFolderHandle(utils) {
	const chan = yield call(recFolderDownload, utils)
	let data
	try {
		while (true) {
			// compressProgress
			let cp = yield take(chan)

			if (cp.data) data = cp
				else if (cp.payload) yield put({ type: "files/setUploadLoading", payload: cp.payload })
		}
	} finally { return data }
}



export function recFolderDownload(utils) {
	return eventChannel(emitter => {

		let b = utils.branch
		let xLsT = utils.xLsT
		let token = utils.token
		let aName = utils.customName ? utils.customName : b.name ? b.name : "export"
		let fName = `${aName}.zip`
		let targetSize = utils.targetSize
		let treatedSize = 0
		//let listLength = 0


		function changeLoaderDesc(customMsg) {
			let actualPart = 10 + ( 70 * treatedSize / targetSize )
			let totalParts = 100

			return emitter({ payload: { actualPart, totalParts, customMsg } })
		}

		function addTreatedSize(file) {
			treatedSize += file.realSize

			let actualPart = 10 + ( 70 * treatedSize / targetSize )
			let totalParts = 100

			return emitter({ payload: { actualPart, totalParts, customMsg: `2/3 ${I18n.t("files.recDl.step2")} - ${file.name}` } })
		}


		try {
			const archive = archiver('zip', {
				zlib: { level: 9 }
			})

			archive
			.on('warning', function(err) {
				if (err.code === 'ENOENT') return
					else throw err
			})
			.on('error', function(err) {throw err})
			.on('progress', (p) => {

				//if (listLength > p.entries.processed) {

				//	let actualPart = 10 + (p.entries.processed * 80 / listLength)
				//	let totalParts = 100

				//	emitter({ payload: { actualPart, totalParts, customMsg: `1/2 ${I18n.t("compressLoadingClosing")}` } })
				//}
			})


			const output = fs.createWriteStream(`./${fName}`)

			output
			.on('close', function() {
				//console.log(archive.pointer() + ' total bytes')
				//console.log(archive)
				console.log('Archiver has been finalized and the output file descriptor has closed.')

				emitter({ payload: { actualPart: 95, totalParts: 100, customMsg: `3/3 ${I18n.t("files.recDl.step3")}` } })

				fs.readFile(`./${fName}`, function(err, data) {
					if (err) throw err

					let blob = new Blob([ data ], {type: "application/octet-stream"})
					let url = URL.createObjectURL(blob)

					emitter(END)

					// Util to dl archive locally
					var link = document.createElement("a")
					link.setAttribute("href", url)
					link.setAttribute("download", fName)
					document.body.appendChild(link)
					link.click()

					fs.rmdir('', { recursive: true } )
					//window.indexedDB.deleteDatabase("IDBWrapper-level-filesystem")
				})
			})
			archive.pipe(output)



			var toBuffer = require('blob-to-buffer')

			async function treatFile(file,fName) {
				//console.log(`starting ${file.name}`)
				//console.log(file)
				changeLoaderDesc(`2/3 ${I18n.t("files.recDl.step2")} - ${fName}`)
				let res = await Api.downloadFile({ token, xLsT, noDirectDL: true, action: {fileName: file.name, id: file.id} })
				//console.log(res)

				return new Promise(resolve => {
					toBuffer(res.target.response, (err, buffer) => {
						archive.append(buffer, { name: fName } )
						console.log(`${fName} appended to archive`)
						addTreatedSize(file)
						resolve()
					})
				})
			}


			async function gMapUtil(f,name,noRecursive=false) {
				return new Promise(resolve => {
					fs.mkdir(name, async () => {

						// On récupère les fichiers pour le dossier actuel si ils ne sont pas donnés par structure émulée
						if (!(f.files)) f.files = (await Api.getFolder({ token, xLsT, id: f.id })).files

						// On traîte les fichiers un par un
						let treatedFilesNames = []

						f.files.reduce(
							(chain, file) => chain.then(() => {
								let fileName = ""

								if (treatedFilesNames.includes(file.name)) {
									let i = 2
									fileName = `${file.name.split('.').slice(0, -1).join('.')}(${i}).${file.name.split('.').pop()}`
									while (treatedFilesNames.includes(fileName)) {
										i++
										fileName = `${file.name.split('.').slice(0, -1).join('.')}(${i}).${file.name.split('.').pop()}`
										//console.log(fileName)
									}
								} else fileName = file.name

								treatedFilesNames.push(fileName)

								return treatFile(file,name+'/'+fileName)
							}),
							Promise.resolve()
						).then(() => {
							if (f.children && !noRecursive) { // Puis on relance la fonction recursivement si sous-dossiers

								f.children.reduce(
									(chain, subFolder) => chain.then(() => gMapUtil(subFolder,name+'/'+subFolder.name)),
									Promise.resolve()
								).then(() => {return resolve()})

							} else return resolve() // Sauf pour le premier appel depuis gMapUtil qui ne sert qu'à créer la racine de l'archive
						})
					})
				})
			}

			async function gMap(tree,name) {
				await gMapUtil(b,name,true)

				if (b.children) {
					// On les traîte un par un
					b.children.reduce(
						(chain, item) => chain.then(() => gMapUtil(item,name+'/'+item.name)),
						Promise.resolve()
					).then(() => {
						console.log("beforeFinalise")
						emitter({ payload: { actualPart: 85, totalParts: 100, customMsg: `3/3 ${I18n.t("files.recDl.step3")}` } })
						return archive.finalize()
					})
				} else {
					console.log("beforeFinalise")
					emitter({ payload: { actualPart: 85, totalParts: 100, customMsg: `3/3 ${I18n.t("files.recDl.step3")}` } })
					return archive.finalize()
				}
			}

			gMap(b,`/${aName}`)

		} catch(e) {
			toastr.warning(I18n.t("forServices.compressFailed"))
		}

		return () => {
			try {
				fs.rmdir(`/${fName}`, { recursive: true } )
				fs.unlink(`./${fName}`)
			} catch(e) {
				console.log("Error cleaning IndexedDB : ", e)
			}
		}
	})
}*/

export function* getDataPermalink(action) {
	yield put({ type: "files/toggleLoading", payload: true })
	yield put({ type: "files/setCR", payload: true })

	const xLsT = yield select((state) => state.auth.xLsToken)
	const token = yield select((state) => state.auth.token.token)
	const branchInfo = yield select((state) => state.files?.branchInfo)

	const historyData = []
	const cleanHistoryData = []
	const defaultFolderData = {
		comp: "File",
		repName: "mLayout.folder",
		fileName: "files.filesList",
		rootLoadData: "LF_GET_DATA_SAGA",
		loadData: "LF_GET_DATA_PERMALINK_SAGA",
		boardHelp: "lF",
		showHelp: "lF",
		isInCategory: true,
	}

	let reps = []
	let fileId = action.payload?.fileId
	let folderId = action.payload?.folderId
	let folderData = {}
	let flattenedTree = []
	let userHasAccess = true
	let isArboLoaded = yield select((state) => state.pass.isArboLoaded)
	let sharedSpaceRootFolders = yield select(
		(state) => state.files?.sharedSpaceRootFolders,
	)
	let treeFolderIds = []

	/* -------------------------------- FUNCTIONS ------------------------------- */
	const createHistory = (
		branchData,
		currentFolderId,
		currentFolderParentId,
	) => {
		branchData.forEach((branch) => {
			if (branch?.parentId === currentFolderId) {
				return reps.push({
					...defaultFolderData,
					...branch,
				})
			} else if (
				branch?.id !== currentFolderId &&
				branch?.parentId !== currentFolderParentId
			) {
				historyData.push({
					...defaultFolderData,
					...branch,
				})
			}

			if (branch?.children?.length > 0) {
				return createHistory(
					branch?.children,
					currentFolderId,
					currentFolderParentId,
				)
			}
		})
	}

	const cleanHistory = (history, currentFolderParentId) => {
		history.forEach((data) => {
			if (
				data?.id === currentFolderParentId &&
				data?.parentId !== currentFolderParentId
			) {
				cleanHistoryData.push(data)

				return cleanHistory(history, data?.parentId)
			}
		})
	}

	const setFlattenedTree = (tree) => {
		tree.forEach((branch) => {
			flattenedTree.push(branch)

			if (branch?.children?.length) {
				setFlattenedTree(branch?.children)
			}
		})
	}
	/* ------------------------------ END FUNCTIONS ----------------------------- */

	if (folderId) {
		let branchTreeData = []
		const setTreeFolderIds = (branchData) => {
			branchData?.forEach((branch) => {
				if (branch?.id) {
					treeFolderIds.push(branch?.id)
				}

				if (branch?.children) {
					setTreeFolderIds(branch?.children)
				}
			})
		}
		if (!action?.payload?.isSharedCat) {
			folderData = yield call(Api.getFolder, {
				xLsT,
				token,
				id: folderId,
			})

			// VERIFY API CALL RESULT
			if (folderData === "sessionExpired") {
				return yield put({ type: "auth/showPadlock" })
			} else if (folderData === "sessionDestroyed") {
				return yield put({ type: "auth/sessionExpired" })
			} else if (folderData === true || folderData.mainId !== 0) {
				return yield put({
					type: "files/setErrorNotFound",
					payload: true,
				})
			}

			if (fileId && folderData) {
				if (
					!folderData?.files?.find(
						(file) => file?.id === parseInt(fileId),
					)
				) {
					return yield put({
						type: "files/setErrorNotFound",
						payload: true,
					})
				}
			}

			if (
				action?.payload?.resetBranchInfo ||
				!branchInfo ||
				!branchInfo?.ids ||
				!branchInfo?.tree ||
				branchInfo?.currentMainId !== folderData?.mainId
			) {
				const persoBranchData = yield call(Api.getPersoTree, {
					xLsT,
					token,
				})

				// VERIFY API CALL RESULT
				if (persoBranchData === "sessionExpired") {
					return yield put({ type: "auth/showPadlock" })
				} else if (persoBranchData === "sessionDestroyed") {
					return yield put({ type: "auth/sessionExpired" })
				} else if (persoBranchData === true) {
					console.log(
						"[API CALL] - error when calling perso branch endpoint",
					)
					return yield put({
						type: "files/setErrorNotFound",
						payload: true,
					})
				}

				branchTreeData = {
					ids: [],
					tree: persoBranchData,
				}
			} else {
				branchTreeData = branchInfo
			}
		} else {
			if (
				action?.payload?.resetBranchInfo ||
				!branchInfo ||
				!branchInfo?.ids ||
				!branchInfo?.tree ||
				branchInfo?.currentMainId !== folderData?.mainId
			) {
				const rootBranchData = yield call(Api.getRootFolders, {
					xLsT,
					token,
				})

				let data = checkIsInCat(rootBranchData)
				let copyData = [...data]

				copyData.map((item) => {
					if (item.children) return delete item.children
					else return undefined
				})

				yield put({
					type: "files/setSharedSpaceRootFolders",
					payload: copyData,
				})

				// VERIFY API CALL RESULT
				if (rootBranchData === "sessionExpired") {
					return yield put({ type: "auth/showPadlock" })
				} else if (rootBranchData === "sessionDestroyed") {
					return yield put({ type: "auth/sessionExpired" })
				} else if (rootBranchData === true) {
					console.log(
						"[API CALL] - error when calling perso branch endpoint",
					)
					return yield put({
						type: "files/setErrorNotFound",
						payload: true,
					})
				}

				branchTreeData = rootBranchData
				setTreeFolderIds(branchTreeData?.tree ?? [])
			} else {
				branchTreeData = branchInfo

				setTreeFolderIds(branchTreeData?.tree ?? [])
			}

			sharedSpaceRootFolders = yield select(
				(state) => state.files?.sharedSpaceRootFolders,
			)

			const rootFolder = sharedSpaceRootFolders?.find((folder) => {
				return folder?.id === parseInt(folderId)
			})

			if (rootFolder !== undefined && !backwardCompApi("1.20.16")) {
				const rootFolderData = yield call(Api.getBranch, {
					xLsT,
					token,
					mainId: rootFolder?.mainId,
				})

				setTreeFolderIds(!rootFolder?.tree ? [] : rootFolder?.tree)

				// VERIFY API CALL RESULT
				if (rootFolderData === "sessionExpired") {
					return yield put({ type: "auth/showPadlock" })
				} else if (rootFolderData === "sessionDestroyed") {
					return yield put({ type: "auth/sessionExpired" })
				} else if (rootFolderData === true) {
					return yield put({
						type: "files/setErrorNotFound",
						payload: true,
					})
				}

				branchTreeData = rootFolderData

				if (!rootFolderData?.ids?.includes(parseInt(folderId))) {
					userHasAccess = false
				}
			} else if (
				!!branchTreeData?.ids &&
				!branchTreeData?.ids?.includes(parseInt(folderId))
			) {
				userHasAccess = false
			}

			if (userHasAccess) {
				folderData = yield call(Api.getFolder, {
					xLsT,
					token,
					id: folderId,
				})

				// VERIFY API CALL RESULT
				if (folderData === "sessionExpired") {
					return yield put({ type: "auth/showPadlock" })
				} else if (folderData === "sessionDestroyed") {
					return yield put({ type: "auth/sessionExpired" })
				} else if (folderData === true || folderData.mainId === 0) {
					return yield put({
						type: "files/setErrorNotFound",
						payload: true,
					})
				}

				if (fileId && folderData) {
					if (
						!folderData?.files?.find(
							(file) => file?.id === parseInt(fileId),
						)
					) {
						return yield put({
							type: "files/setErrorNotFound",
							payload: true,
						})
					}
				}
			} else {
				const accessibleFolderIds = branchTreeData.tree.map(
					(branch) => branch.id,
				)

				if (accessibleFolderIds?.includes(parseInt(folderId))) {
					const rootFolderData = yield call(Api.getBranch, {
						xLsT,
						token,
						mainId: parseInt(folderId),
					})

					branchTreeData = rootFolderData

					folderData = {
						...rootFolderData.tree[0],
						reps: rootFolderData.tree[0].children,
						mainId: parseInt(folderId),
						parentId: -1,
					}

					reps = rootFolderData.tree[0].children
				}
			}

			if (
				action?.payload?.resetBranchInfo ||
				!branchInfo ||
				!branchInfo?.tree ||
				!branchInfo?.ids ||
				!branchInfo?.currentMainId ||
				!treeFolderIds.includes(parseInt(action?.payload?.folderId))
			) {
				const rootBranchData = yield call(Api.getBranch, {
					xLsT,
					token,
					mainId: folderData?.mainId
						? folderData?.mainId
						: rootFolder?.mainId ?? 1,
				})

				// VERIFY API CALL RESULT
				if (rootBranchData === "sessionExpired") {
					return yield put({ type: "auth/showPadlock" })
				} else if (rootBranchData === "sessionDestroyed") {
					return yield put({ type: "auth/sessionExpired" })
				} else if (rootBranchData === true) {
					console.log(
						"[API CALL] - error when calling perso branch endpoint",
					)
					return yield put({
						type: "files/setErrorNotFound",
						payload: true,
					})
				}

				if (rootBranchData?.tree) {
					branchTreeData = {
						...rootBranchData,
						tree: [
							rootBranchData?.tree?.find(
								(branch) =>
									branch?.id === folderData?.mainId ||
									rootFolder?.mainId,
							),
						],
					}
				}
			} else {
				branchTreeData = branchInfo
			}
		}

		if (!action?.payload?.isSharedCat) {
			if (action?.payload?.personnalSpaceBlock) {
				return yield put({
					type: "files/setErrorNotFound",
					payload: true,
				})
			}
			createHistory(
				branchTreeData?.tree,
				folderData?.id,
				folderData?.parentId,
			)
			cleanHistory(historyData, folderData?.parentId)
		} else {
			setFlattenedTree(branchTreeData?.tree)

			let inHistory = true

			flattenedTree.forEach((branch) => {
				if (inHistory) {
					if (branch?.id !== parseInt(action?.payload?.folderId)) {
						historyData.push({
							...defaultFolderData,
							static: branch?.id === -1 ? true : false,
							name: branch?.name,
							id: branch?.id,
							mainId: branch?.mainId,
							parentId: branch?.parentId,
						})
					} else {
						folderData = {
							...defaultFolderData,
							...folderData,
							isInCategory: branchTreeData?.ids?.includes(
								branch?.id,
							),
							static: false,
							name: branch?.name,
							id: branch?.id,
							mainId: branch?.mainId,
							parentId: branch?.parentId,
						}

						if (branch?.children?.length > 0) {
							reps = checkDeadEnd(
								branch,
								branchTreeData?.ids,
							)?.reps?.map((child) => ({
								...defaultFolderData,
								isInCategory: branchTreeData?.ids?.includes(
									child?.id,
								),
								static: false,
								name: child?.name,
								id: child?.id,
								mainId: child?.mainId,
								parentId: child?.parentId,
							}))
						}

						inHistory = false
					}
				}
			})

			if (!folderData?.id && userHasAccess) {
				return yield put({
					type: "files/setErrorNotFound",
					payload: true,
				})
			}

			cleanHistory(historyData, folderData?.parentId)
		}

		yield put({
			type: "files/saveBranch",
			payload: {
				...branchTreeData,
				currentMainId: folderData?.mainId,
			},
		})
		yield put({
			type: "files/enterInFolder",
			payload: {
				domTarget: 0,
				categoryId: folderData?.id,
				productTarget: folderData?.mainId === 0 ? 0 : 1,
				reset: true,
				...defaultFolderData,
				...folderData,
				users: [
					...(folderData?.users ? folderData?.users : []),
					...(folderData?.groups ? folderData?.groups : []),
				],
				reps,
				historyData: cleanHistoryData?.reverse(),
			},
		})

		if (!branchInfo && !isArboLoaded && backwardCompApi("1.20.16")) {
			yield put({
				type: "LF_GET_DATA_SAGA",
				payload: {
					categoryId: 0,
					initCall: true,
					stateTarget: 1,
					initArbo: true,
				},
			})
		} else {
			yield put({ type: "files/toggleLoading" })
			yield put({ type: "files/setCR" })
		}
	}
}

export default function* filesSagas() {
	yield takeLeading("LF_GET_TREE_SAGA", getTree)
	yield takeLeading("LF_GET_PERSO_TREE_SAGA", getPersoTree)
	yield takeLeading("LF_GET_DATA_SAGA", getData)

	yield takeLeading("LF_GET_FOLDER_REPORT", getFolderReport)
	yield takeLeading("LF_POST_FOLDER_SAGA", postFolder)
	yield takeLeading("LF_PUT_FOLDER_SAGA", putFolder)
	yield takeLeading("LF_DELETE_FOLDER_SAGA", deleteFolder)
	yield takeLeading("LF_PUT_FOLDER_NOTIFICATION_SAGA", putNotification)
	yield takeLeading("LF_GET_BRANCH_SAGA", getBranch)

	yield takeLeading("LF_PUT_FILE_SAGA", putFile)
	yield takeLeading("LF_DELETE_FILE_SAGA", deleteFile)

	yield takeLeading("LF_MOVE_FOLDER_SAGA", moveFolder)

	yield takeLeading("LF_FOLDER_ADD_GROUP_SAGA", folderAddGroup)
	yield takeLeading("LF_FOLDER_ADD_USER_SAGA", folderAddUser)
	yield takeLeading("LF_FOLDER_DEL_GROUP_SAGA", folderDelGroup)
	yield takeLeading("LF_FOLDER_DEL_USER_SAGA", folderDelUser)
	yield takeLeading("LF_FOLDER_CHANGE_USER_GRADE_SAGA", folderChangeUserGrade)
	yield takeLeading(
		"LF_FOLDER_CHANGE_GROUP_GRADE_SAGA",
		folderChangeGroupGrade,
	)
	yield takeLeading("LF_DOWNLOAD_SELECTED_FILES_SAGA", downloadSelectedFiles)

	yield takeLeading("LF_DOWNLOAD_FILE_SAGA", downloadFile)
	yield takeLeading("LF_DOWNLOAD_FOLDER_SAGA", downloadFolder)
	yield takeLeading("LF_GET_FOLDER_SIZE_SAGA", getFolderSize)

	/* -------------------------- LOCKFILES PERMALINKS -------------------------- */
	yield takeLeading("LF_GET_DATA_PERMALINK_SAGA", getDataPermalink)
	/* ------------------------ END LOCKFILES PERMALINKS ------------------------ */

	const channel = yield actionChannel("LF_WITH_BUFFER_SAGA")

	while (true) {
		const payload = yield take(channel)

		switch (payload.payload.channelMod) {
			case "uploadFile": {
				yield call(uploadFilesAction, payload)
				break
			}
			case "vanilla_uploadFile": {
				yield call(uploadFile, payload)
				break
			}
			case "vanilla_deleteFile": {
				yield call(deleteFile, {
					payload: {
						payload: payload?.payload?.ids,
						canEstimate: payload?.payload?.canEstimate,
						file: payload?.payload?.file,
					},
					fromUpload: true,
				})
				break
			}
			case "putFile": {
				yield call(putFile, payload)
				break
			}
			default:
				return null
		}
	}
}

/*
export function* uploadSagaChannel() {
	const uploadChannel = yield actionChannel("LF_UPLOAD_FILES_SAGA")

	while (true) {
		const payload = yield take(uploadChannel)
		const a = yield call(uploadFilesAction, payload)
	}
}*/
